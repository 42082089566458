import * as React from "react";
import moment from "moment";

import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import useHighlighterColor from "@/viewer/ui/modules/common/hooks/useSlotHighlightColor";
import { getSlotEffects } from "@/viewer/utils/domain/slotquests";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { isSlot } from "@/viewer/utils/domain/slotquests";
import classnames from "@/_lib/utils/classnames";

enum CellType {
  Even,
  Odd,
  Default,
}
interface Props {
  slot: SlotOrRequest;
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  slotEffects: React.CSSProperties;
  isDataGroup?: boolean;
  cellType?: CellType;
}

const SlotContextClues = (props: Props): JSX.Element | null => {
  const { slot, settings, ui, data, tools, isDataGroup, cellType = CellType.Default, slotEffects } = props;

  if (settings.layout === "block") return null;

  const { style } = getSlotEffects(slot, settings, ui, data, tools);
  const contextualClueItems: JSX.Element[] = [];
  const dataGroupBackgroundColor = "#F6F3E3";
  const { showIcons, showTimes, isBulkMode } = ui;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const highlighterColor = useHighlighterColor(ui, slot);
  const { LbsAppData } = window as any;

  const checkRequestIconVisualPermission = (): boolean => {
    const { AppContext, User, Templates } = LbsAppData;
    const isAdmin = User.get("is_admin") || User.get("is_super_admin");
    const currentView = AppContext.get("view");
    const currentViewTemplateIds = currentView?.filter?.on_templates || [];
    const isDefaultView = currentView?.name === "Me" || currentView?.name === "Daily";
    const genWithPendingFlagOn = AppContext.get("lv10335FlagOn") || false;
    const masterRequestCalendarId = -98765;

    if (!genWithPendingFlagOn) {
      return true;
    }

    if (isAdmin) {
      return true;
    }

    if (!Templates?.models?.length || (!currentViewTemplateIds.length && !isDefaultView)) {
      return false;
    }

    const currentTemplates = isDefaultView
      ? Templates.models.filter((template: any) => template.attributes.department_id !== masterRequestCalendarId)
      : Templates.models.filter((template: any) => currentViewTemplateIds.includes(template.id));

    if (!currentTemplates.length) {
      return false;
    }

    return isLastPublishedDateBeforeSlotDate(currentTemplates);
  };

  const isLastPublishedDateBeforeSlotDate = (currentTemplates: { [key: string]: any }): boolean => {
    const mostRecentlyPublishedTemplate = currentTemplates.reduce((latest: any, current: any) => {
      const latestDate = new Date(latest.get("last_published_date"));
      const currentDate = new Date(current.get("last_published_date"));
      return currentDate > latestDate ? current : latest;
    });

    const lastPublishedDate = mostRecentlyPublishedTemplate.get("last_published_date");
    const startDate = slot.dateString;

    // Current View start Date occurs after last published date of View relevant templates --therefore can render icon
    const publishedScheduleIsInPast = moment(startDate).isAfter(moment(lastPublishedDate));

    return publishedScheduleIsInPast;
  };

  if (slot.message || (isSlot(slot) && slot.hasNote)) {
    contextualClueItems.push(<i key="note-clue" className="fas fa-sticky-note noteIcon slotIcon" />);
  }

  if (showIcons && checkRequestIconVisualPermission()) {
    if (slot.status === "pending") {
      contextualClueItems.push(<i key="pending-clue" className="fas fa-question pendingIcon slotIcon" />);
    } else if (slot.status === "denied") {
      contextualClueItems.push(<i key="denied-clue" className="fas fa-times deniedIcon slotIcon" />);
    } else if (!isSlot(slot) && slot.status === "deleted") {
      contextualClueItems.push(<i key="deleted-clue" className="far fa-trash-alt deletedIcon slotIcon" />);
    } else if (isSlot(slot) && slot.isGrantedRequest) {
      contextualClueItems.push(
        <div key="request-clue" className="contextual-clue isWebRequest" style={{ marginLeft: "4px" }} />
      );
    } else if (!slot.status && slot.empRequestId) {
      contextualClueItems.push(
        <div key="request-clue" className="contextual-clue isWebRequest" style={{ marginLeft: "4px" }} />
      );
    } else if (!isSlot(slot) && slot.status === "granted") {
      contextualClueItems.push(<i key="granted-clue" className="fas fa-check grantedIcon slotIcon" />);
    }
  }
  if (contextualClueItems.length === 0) {
    return null;
  }

  // turn on the background colour change stuff so the icon background matches the cell background
  const activateChromatophoreCamouflage = () => {
    if (!isBulkMode || (isBulkMode && !style?.background)) {
      if (highlighterColor) {
        return { backgroundColor: highlighterColor };
      }
    }

    if (style?.backgroundColor) {
      return { backgroundColor: style.backgroundColor };
    }

    if (slotEffects?.backgroundColor) {
      return { backgroundColor: slotEffects.backgroundColor };
    }

    if (slotEffects?.background) {
      return { background: slotEffects.background, backgroundSize: slotEffects?.backgroundSize };
    }

    if (isDataGroup) {
      return { backgroundColor: dataGroupBackgroundColor };
    }

    switch (cellType) {
      case CellType.Even: {
        return { backgroundColor: "#E1E1E1" };
      }

      case CellType.Odd: {
        return { backgroundColor: "#F3F3F3" };
      }

      default:
        return {};
    }
  };

  return (
    <div
      className={classnames("contextual-clues", { "show-times": showTimes })}
      style={activateChromatophoreCamouflage()}
    >
      {contextualClueItems}
    </div>
  );
};

export default SlotContextClues;
